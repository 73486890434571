.App {
  padding-top: 41px;
  text-align: center;
  margin: auto;
  font-family: 'Nunito', cursive;
}

h1 {
  font-family: 'Lobster', cursive;
}

h1.ui.header {
  font-family: 'Lobster', cursive;
}

h3.ui.dividing.header {
  font-family: 'Nunito', cursive;
}

h3.ui.header {
  font-family: 'Nunito', cursive;
}

.map-holder {
  height: 50%;
  width: 80%;
  margin: auto;
  position: static;
}

.table-holder {
  padding-top: 780px;
  width: 80%;
  margin: auto;
}

.auth-holder {
  padding-top: 20px;
}

.form.standard-form {
  padding-bottom: 20px;
  margin: auto;
  width: 80%;
}

.dropdown {
  margin: auto;
  width: 80%;
}

.search-back-button {
  margin-top: 20px !important;
}

.home-button {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 10px !important;
}

.account-button {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.delete-photo {
  margin-top: 5px !important;
  margin-left: 34.275px !important;
  margin-right: 34.275px !important;
}

.home-image {
  display: block;
  margin: auto;
  border-radius: 5%;
  max-height: 80% !important;
  max-width: 80% !important;
}

.modal-form {
  text-align: center;
}

.user-search-holder {
  margin: auto;
  width: 80%;
  height: 80%;
  padding-bottom: 10px;
}

.comment-row {
  max-width: 400px;
}

.difficulty {
  margin-bottom: 1px;
}

.info-holder {
  margin: auto;
  width: 80%;
  padding-bottom: 10px;
}

.info-loader {
  height: 224px;
}

.info-image {
  margin: auto;
  max-height: 80%;
  max-width: 80%;
  border-radius: 3%;
}

.form {
  padding-bottom: 10px;
}

.map-marker {
  position: absoloute;
  width: 40px;
  height: 40px;
  left: -20px;
  top: -20px;
  border-radius: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
  cursor: pointer;
}

.map-marker img {
  z-index: 0;
}

.marker-info {
  overflow: auto;
  width: 150px;
  height: 200px;
  left: -6.5px;
  margin-left: 0px;
  cursor: auto;
}

.trail-holder {
  padding-bottom: 200px;
}

.demo-video {
  width: 560px;
  height: 315px;
  display: block;
  margin: auto;
}

@media only screen and (max-width: 1200px) {
  .demo-video {
    width: 280px;
    height: 157px;
  }
}

@media only screen and (min-height: 1200px) and (max-height: 1500px) {
  .table-holder {
    padding-top: 1300px;
  }
}

@media only screen and (min-width: 1300px) {
  .table-holder {
    padding-top: 950px;
  }
  .home-image {
    width: 60%;
  }
  .ui.stacked.segment {
    margin: auto;
    width: 70%;
  }
  .ui.message {
    margin: auto;
    width: 70%;
  }
  .ui.selection.dropdown {
    width: 56%
  }
}
